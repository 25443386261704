import axios from 'axios'

import pagina from "@/class/pagina";

export class DisciplinaEscolar {
  static async obtemTodos () {
    return axios.get('/disciplinas')
  }
  static async obtemTodosPag (n) {
    const routePg = pagina.routePg;

    return axios.get('/disciplinas/pg?page='+n+routePg)
  }
  static async obtemTodosSomenteTrue () {
    return axios.get('/disciplinas-turmas')
  }
  static async obtemTodosSomenteTrue2 (turma_id) {
    return axios.get('/disciplinas-turmas/turma/'+ turma_id)
  }
  static async obtemTodosSemId1 () {
    return axios.get('/sid1/disciplinas')
  }
  static async obtemTodosSemId1Turma (id) {
    return axios.get('/sid1/turma/disciplinas/'+ id,{
      headers: {
        'cache-control': 'max-age=3600',
      }
    })
  }
  static async obtemUm (id) {
    return axios.get('/disciplinas/'+id)
  }
  static async cadastrar (payload) {
    return axios.post('/disciplinas', payload)
  }
  static async alterar (payload) {
    return axios.put('/disciplinas/'+payload.id, payload)
  }
  static async alterarStatus (payload) {
    return axios.put('/disciplinas/status/'+payload.id, payload)
  }
  static async remover (id) {
    return axios.delete('/disciplinas/'+id)
  }

  static async pesqDisciplinaCompleto (payload) {
    return axios.get('/disciplinas/pesquisa/'+payload+'/completa')
  }

}
