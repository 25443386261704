<template>
  <section>
    <h2 class="titulo-links-escolha" ref="topo">
      Cadastrar Automação de Disciplinas
    </h2>

    <pm-Card>
      <template v-slot:content>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-5">
            <label>Selecione o Segmento</label>
            <select
              class="p-inputtext p-component"
              v-model="auto.segmento_id"
              @change="listarSerie(auto.segmento_id)"
              style="appearance: revert !important; width: 100% !important"
            >
              <option value="0">-- Escolha um Segmento --</option>
              <option v-for="n in segmentosALL" :key="n" :value="n.id">
                {{ n.nome }}
              </option>
            </select>
          </div>
          <div class="field col-12 md:col-12">
            <label>Selecione os Períodos:</label>
            <div class="col-md-12" v-for="n in serieALL" :key="n">
              <label class="form-check-label">
                <input
                  type="checkbox"
                  v-model="auto.serie_id"
                  class="form-check-input"
                  :value="n.id"
                  style="margin-right: 10px"
                />
                {{ n.nome }}
              </label>
            </div>
          </div>

          <div class="field col-12 md:col-12" style="margin-bottom: 20px">
            <label for="sel1" style="padding-top: 15px"
              >Selecione as Disciplina:</label
            >
            <div class="col-md-12" v-for="n in disciplinaALL" :key="n">
              <label class="form-check-label">
                <input
                  type="checkbox"
                  v-model="auto.disciplina_id"
                  class="form-check-input"
                  :value="n.id"
                  style="margin-right: 10px"
                />
                {{ n.nome }}
              </label>
            </div>
          </div>

          <div class="field col-12 md:col-2">
            <pm-Button
              label="Voltar"
              class="p-button-danger"
              icon="pi pi-angle-left"
              iconPos="left"
              @click="this.$router.push({ name: 'automatizar' })"
            />
          </div>

          <div class="field col-12 md:col-2">
            <pm-Button
              label="Cadastrar"
              @click="cadastrar"
              v-if="
                auto.segmento_id &&
                auto.serie_id.length > 0 &&
                auto.disciplina_id.length > 0
              "
            />
          </div>
        </div>
      </template>
    </pm-Card>
  </section>
</template>

<script>
import axios from "axios";
import { SegmentoEscolar } from "@/class/segmentoEscolar";
import { SeriesEscolar } from "@/class/serie";
import { DisciplinaEscolar } from "@/class/disciplina";

export default {
  components: {},
  name: "conf-cadastro",
  data() {
    return {
      disciplinaALL: [],
      segmentosALL: [],
      serieALL: [],
      auto: {
        segmento_id: 0,
        serie_id: [],
        disciplina_id: [],
      },
      verificacao: [],
    };
  },
  methods: {
    async listarSegmento() {
      let data = await SegmentoEscolar.obtemTodos();
      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].seg_automatizada === 0 && data.data[i].tipo) {
          this.segmentosALL.push(data.data[i]);
        }
      }
    },
    async listarSerie(seg) {
      this.serieALL = [];
      let data = await SeriesEscolar.buscaPorSegmento(seg);

      let dt1 = await axios.get("config/p/d/verificar/" + seg + "/" + 0);
      this.verificacao = dt1.data;

      for (const el of data.data) {
        if (el.tipo != 3) {
          this.serieALL.push(el);
        }
      }

      for (const a of this.verificacao) {
        this.serieALL = this.serieALL.filter(function check(item) {
          return item.id != parseInt(a.serie);
        });
      }
    },
    async listarDisciplina() {
      let data = await DisciplinaEscolar.obtemTodosSomenteTrue();

      for (const el of data.data) {
        if (el.id != 1 && el.id != 2) {
          this.disciplinaALL.push(el);
        }
      }
    },
    async cadastrar() {
      let disciplinas = "";
      let series = "";
      for (const disc of this.auto.disciplina_id) {
        disciplinas += disc + "-";
      }
      for (const ser of this.auto.serie_id) {
        series += ser + "-";
      }

      if (disciplinas.length > 1) {
        disciplinas = disciplinas.substring(0, disciplinas.length - 1);
      }
      if (series.length > 1) {
        series = series.substring(0, series.length - 1);
      }

      this.auto.serie_id = series;
      this.auto.disciplina_id = disciplinas;

      if (this.auto.serie_id == "") {
        return false;
      }
      if (this.auto.disciplina_id == "") {
        return false;
      }

      let data = await axios.post("config/padrao/disciplinas", this.auto);
      this.$router.push({ name: "automatizar" });
    },
  },
  async beforeMount() {
    await this.listarSegmento();
    await this.listarDisciplina();
  },
};
</script>

<style>
.row.row-inside {
  max-width: none;
}

.form-control:focus {
  background-color: #f6f7f6;
  border-color: #673ab7;
  box-shadow: none;
}
.form-control {
  background-color: #f6f7f6;
  border: 1px solid #f6f6f6;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.va-input-wrapper,
fieldset {
  margin-bottom: 0.5rem;
}
</style>
